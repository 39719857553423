<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/questionarios">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/questionarios"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="questionarios-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="name">Nome</label>
                                <InputText
                                    v-model.trim="form.nome"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Informe o nome"
                                    :class="{ 'p-invalid': submitted && !form.nome }"
                                />
                                <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="tipo">Tipo</label>
                                <Dropdown
                                    id="tipo"
                                    class="selects"
                                    v-model="form.tipo"
                                    :options="tipos"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipo }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="customers">Clientes</label>
                                <MultiSelect
                                    id="customers"
                                    class="multiSelect"
                                    placeholder="Selecione..."
                                    v-model="form.clientes"
                                    :filter="true"
                                    @filter="loadCustomers"
                                    :maxSelectedLabels="10"
                                    :virtualScrollerOptions="{ itemSize: 60 }"
                                    :options="clientes"
                                    optionLabel="name"
                                    dataKey="id"
                                    :class="{ 'p-invalid': submitted && !form.clientes }"
                                />
                                <small class="p-error" v-if="submitted && !form.clientes">Clientes é obrigatório.</small>
                            </div>

                            <div class="field col-6">
                                <label for="perguntas">Perguntas</label>
                                <MultiSelect
                                    id="perguntas"
                                    class="multiSelect"
                                    placeholder="Selecione..."
                                    v-model="form.perguntas"
                                    :filter="true"
                                    @filter="loadPerguntas"
                                    :maxSelectedLabels="10"
                                    :virtualScrollerOptions="{ itemSize: 60 }"
                                    :options="perguntas"
                                    optionLabel="descricao"
                                    dataKey="id"
                                    :class="{ 'p-invalid': submitted && !form.perguntas }"
                                />
                                <small class="p-error" v-if="submitted && !form.perguntas">Perguntas é obrigatório.</small>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            tipos: [
                {
                    value: 'Q',
                    label: 'Questionário de qualidade de vida'
                },
                {
                    value: 'A',
                    label: 'Pré-Anamsene'
                }
            ],
            clientes: [],
            loadingClientes: false,
            perguntas: [],
            loadingPerguntas: false
        };
    },
    created() {
        this.service = new BaseService('/questionario');
    },
    async mounted() {
        this.$servicePergunta = new BaseService('/pergunta');
        this.$serviceCliente = new BaseService('/customers/findall-semfiltro-tenant');
        await Promise.all([this.loadCustomers(), this.loadPerguntas()]);
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar questionário' : 'Adicionar questionário';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = {
                companyId: data.companyId,
                id: data.id,
                nome: data.nome,
                clientes: data.questionarioCliente?.map((qc) => {
                    return qc.cliente;
                }),
                perguntas: data.questionarioPergunta?.map((qc) => {
                    return qc.pergunta;
                }),
                tipo: data.tipo
            };
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.tipo) return true;
            if (!this.form.clientes) return true;
        },
        async loadCustomers(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$serviceCliente.findAll({ limit: 9999, page: 1, filter: event?.value, companyId });
            this.clientes = data.items;
        },
        async loadPerguntas(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$servicePergunta.findAll({ filter: event?.value, limit: 9999, companyId });
            this.perguntas = data.items;
        }
    },
    components: { AppInfoManual }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}
</style>
